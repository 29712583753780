<script setup lang="ts">
import { toast } from "vue3-toastify";

import type { DetailInvite, RewardNotice } from "@/types";

const { t } = useT();
const { add } = useSockets();

const { data } = useAppInitData();
const registrationType = useRegistrationType();
const route = useRoute();
const { open } = useNlcModals();
const { handleOpenGame } = useOpenGame(open);
const { isDesktop } = useDevice();
const { showPwaModal } = usePWA();
const { onMessage, onUpdateToken } = useFirebaseNotification();
const { refresh: refreshCheckRewardsData } = useCheckRewardsData({ immediate: false });
const { sendAdAction } = usePopupAds();
const popUpAds = useState("popUpAds");
const refCode = useState("refCode");

useLayoutEventHandlers();

const showToast = (titleText: string, detail: DetailInvite) => {
	toast.success(
		`
			<b class="toast-invite-title color-neutral-100">${titleText}</b>
			<div class="toast-invite-content color-custom-6">${t("You have got")}
				<span class="color-custom-7">${detail.coins ? numberFormat(Number(detail.coins)) : "0"} GC</span>
				<span>${t("and")}</span>
				<span class="color-custom-3">${detail.entries ? numberFormat(Number(detail.entries)) : "0"} SC</span>
			</div>
		`,
		{
			toastId: detail.action,
			icon: h("i", { class: "toast-icon icon-goal" }),
			dangerouslyHTMLString: true,
			theme: toast.THEME.DARK,
			position: isDesktop ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.TOP_CENTER,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000
		}
	);
};

const handleOpenModal = (modal: string) => {
	open(modal as keyof Modals);
};

add("invite", (detail: DetailInvite) => {
	if (detail.action === "friend-qualified") {
		showToast(t("Your friend got qualified!"), detail);
	}
	if (detail.action === "player-qualified") {
		showToast(t("You got qualified!"), detail);
	}
});

add("popUpAds", (detail: EventData<PopUpAdData[]>) => {
	if (detail?.data?.length) {
		popUpAds.value = detail.data.sort((modalA, modalB) => modalA.popup_priority - modalB.popup_priority);
	}
});

add("contacts", async (detail: EventData<RewardNotice>) => {
	if (detail.action === "confirmPhone") {
		dispatchFBEvents("trackCustom", "VerificationSuccess", {
			event_name: "ConfirmedPhone",
			event_time: Date.now(),
			action_source: "website",
			user_data: {
				ph: await sha256(data.value?.phone || "")
			}
		});
	}

	if (detail.action === "confirmEmail") {
		dispatchFBEvents("trackCustom", "VerificationSuccess", {
			event_name: "ConfirmedEmail",
			event_time: Date.now(),
			action_source: "website",
			user_data: {
				em: await sha256(data.value?.email || "")
			}
		});
	}
});

onMounted(() => {
	const { openNotify, invited_by: invitedBy, action, social, pwa, refcode } = route.query;

	if (openNotify === "EMAIL_VERIFIED") {
		if (data.value?.emailConfirmed) {
			open("LazyOModalEmailConfirm");
		}

		if (data.value?.Registration?.registrationFunnel?.funnelType === "closed_funnel") {
			dispatchGAEvent({
				event: "verification",
				location: "header",
				type: data.value?.activeSocialAccounts?.[0] || registrationType.value,
				step: "success",
				playerId: data.value?.login ?? ""
			});
		}
	}

	if (invitedBy) {
		dispatchGAEvent({
			event: "promocode",
			location: "deeplink",
			step: "success"
		});
	}

	if (action || social) {
		dispatchGAEvent({
			event: route.query.action === "register" ? "registration" : "login",
			location: "header",
			type: route.query.social as string,
			step: "success",
			playerId: data.value?.login || ""
		});
	}

	if (showPwaModal.value || pwa) {
		open("LazyOModalPwaApps");
	}

	if (refcode) {
		refCode.value = refcode;
		refreshCheckRewardsData();
	}

	pushNotificationsAddEventListener("message", onMessage);
	pushNotificationsAddEventListener("updateToken", onUpdateToken);
});

onUnmounted(() => {
	pushNotificationsRemoveEventListener("message", onMessage);
	pushNotificationsRemoveEventListener("updateToken", onUpdateToken);
});
</script>
<template>
	<ClientOnly>
		<LazyCash v-if="data?.isGuest === false" />
		<ODynamicAdModal :openGame="handleOpenGame" @send-ad-action="sendAdAction" @open-modal="handleOpenModal" />
		<LazyOCookieControl />
	</ClientOnly>
	<OHeader />
	<main
		:class="[
			'page-content',
			{ 'full-width': route.path === '/' },
			{ 'padding-top-none': route.name === 'tournaments___en' }
		]"
	>
		<slot />
	</main>
	<LazyOFooter />
	<ASvgCollection />
	<MWidgetsFixedSide :data="data" />
</template>

<style lang="scss">
.desktop {
	@include media-breakpoint-down(md) {
		display: none !important;
	}
}

.mobile {
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}
</style>

<style lang="scss">
.Toastify {
	z-index: 999999;

	#friend-qualified,
	#player-qualified {
		width: 344px;
		max-width: 320px;
		padding: gutter(2) gutter(2) gutter(1.5);
		max-width: 100%;
		margin: auto;

		.toast-invite-title {
			display: flex;
			margin-bottom: gutter(0.75);
			font-size: 15px;
			line-height: 21px;
		}
		.toast-invite-content {
			line-height: 21px;
			font-size: 14px;
			font-weight: 500;
		}
		.Toastify {
			&__toast-body {
				display: inline-flex;
				align-items: flex-start;
				gap: gutter(2);
			}
			&__toast-icon {
				width: 64px;
				height: 64px;
				flex-shrink: 0;
				margin: 0;
			}
			&__close-button {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				opacity: 1;
				width: 20px;
				height: 20px;
			}
			&__toast-body {
				white-space: normal;
			}
		}
		.icon-goal {
			width: 100%;
			height: 100%;
			background-image: url(/public/nuxt-img/referral/friends-qualified.png);
		}
	}
}

.desktop {
	@include media-breakpoint-down(md) {
		display: none !important;
	}
}

.mobile {
	@include media-breakpoint-up(md) {
		display: none !important;
	}
}
</style>
<style lang="scss" scoped>
.page-content {
	position: relative;
	width: 100%;
	overflow: clip;
	padding-bottom: 240px;
	margin-bottom: -200px;

	@include media-breakpoint-down(lg) {
		padding-bottom: 224px;
		margin-bottom: -200px;
	}

	&:not(.full-width) {
		max-width: 1428px;
		margin: 0 auto;
		padding: 40px 16px 240px;
		margin-bottom: -200px;

		@include media-breakpoint-down(lg) {
			padding: 24px 16px 224px;
			margin-bottom: -200px;
		}
		@include media-breakpoint-down(md) {
			&.padding-top-none {
				padding-top: 0;
			}
		}
	}
}
</style>
